export const SCRIPTS_CONSTANTS = [
    {
        id: 80,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/80/%D5%A1%D6%80%D5%A1%D5%BA%D5%A1%D5%B7%D5%BF%D5%BA%D5%A1%D5%B6%D5%B6%D5%A5%D6%80"
      },
      "headline": "Արևապաշտպաններ",
      "description": "Եթե դեռ չես օգտագործում արևապաշտպան միջոց ամեն առավոտ, ապա այս նյութը հենց քեզ համար է։ Ձե՛ռք բերեք Ձեր քսուքը հիմա Alfa Pharm-ից:",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": ""
    }`
    },
    {
        id: 146,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/146/alergiayi-tesaknery-kanxargelume"
      },
      "headline": "Ալերգիայի տեսակները, կանխարգելումը",
      "description": "Կախված ալերգիայի բնույթից կանխարգելիչ միջոցառումները տարբեր են։ Կանխարգելեք բոլոր տեսակի ալերգիաները Alfa Pharm-ի հետ։",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": "2023-03-21"
    }`
    },
    {
        id: 179,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/168/arevaharvac-mashk-arevapashtpani-karevorutyune"
      },
      "headline": "Արևահարված մաշկ․ արևապաշտպան քսուքի կարևորությունը",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": "2023-07-19"
    }`
    },
    {
        id: 168,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/168/arevaharvac-mashk-arevapashtpani-karevorutyune"
      },
      "headline": "Արևահարված մաշկ․ արևապաշտպան քսուքի կարևորությունը",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": "2023-07-19"
    }`
    },
    {
        id: 186,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/186/maskov-karevorutyune"
      },
      "headline": "Մաշկի խնամք դիմակով. կարևորությունը և կանոնները",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": "2023-08-15"
    }`
    },
    {
        id: 238,
        script: `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://alfapharm.am/hy/blogs/238/hogevorakan-jerakalutyun"
      },
      "headline": "Հոգևորական ջեռակալություն. բուժման եղանակներ",
      "author": {
        "@type": "Organization",
        "name": "",
        "url": "https://alfapharm.am/hy"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Alfa Pharm",
        "logo": {
          "@type": "ImageObject",
          "url": "https://alfapharm.am/static/media/logo.39fd2acb.png"
        }
      },
      "datePublished": "2024-01-10"
    }`
    }
];
