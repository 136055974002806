import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import {SCRIPTS_CONSTANTS} from '../../BlogScripts';
import { byRoute } from 'platform/decorators/routes';
import Info from './components/info';
import Images from './components/images';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';
import { IBlogDetailsResponseModel } from 'platform/api/blog/models/response';
import BlogController from 'platform/api/blog';

import './style.scss';
import Settings from "../../../../../platform/services/settings";

interface IRouteParams {
    id: string,
    slug: string
};

interface IState {
  data?: IBlogDetailsResponseModel;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byRoute(ROUTES.BLOG.DETAILS)
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {
  public state: IState = {};

  public componentDidMount() { this.fetchData(); }
  // private goBack = () => window.routerHistory.goBack();
  private goBack = () =>window.location.href= window.location.origin+'/blogs';

  private fetchData = async () => {
    const { id } = this.props.match.params;
    const { slug } = this.props.match.params;
    const result = await BlogController.GetDetails(+id,slug);
    if (result.data.redirect){
        window.location.href=window.location.origin+'/blogs/'+id+'/'+result.data.slug
    }
      this.safeSetState({ data: result.data });

      const obj = SCRIPTS_CONSTANTS.find((e) => e.id === Number(id));
      if (obj) {
          const scriptElement = document.createElement("script");
          scriptElement.type = "application/ld+json";
          scriptElement.textContent = obj.script;
          document.head.appendChild(scriptElement);
      }

  }

  public render() {
    const { data } = this.state;

    return data ? (
        <>
          <ReactSEOMetaTags
              website={{
                  title:  data.title,
                  description: data.shortDescription,
                  language: 'en-US',
                  image: data.images.length ? data.images[0].path : '',
              }}/>
            <ul className="Breadcrumbs">
                <li className='fromRoutes'> <Link to={ROUTES.MN}>{Settings.translations.home}</Link></li>
                <li className='fromRoutes'><Link to={ROUTES.BLOG.MAIN}>{Settings.translations.blog}</Link> </li>
                <li className='activeRoute'> { data.title}</li>
            </ul>
          <section className="G-page P-blog-details-page">
            {window.routerHistory.length > 2 && <i className="G-back-icon icon-Group-5529" onClick={this.goBack} />}
            <div className="P-content">
              {!!data.images.length && <Images data={data} />}
              <Info data={data} />
              <p className="P-description" dangerouslySetInnerHTML={{ __html: data.description}} />
            </div>
          </section>
        </>

    ) : <PageLoader />;
  }
};

export default Details;
